import { Accordion, Box } from '@chakra-ui/react';
import React, { RefObject } from 'react';
import AccordionItemComponent from '../features/faq/faq-card';

const getTextByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'MTNNG':
      return 'MyMTNPicknFix';
    case 'AIRNG':
      return 'Airtel PicknFix';
    default:
      return 'PicknFix';
  }
};

const faqViews = process.env.NEXT_PUBLIC_FAQ_VIEWS;

const Faq = ({ sectionRef }: { sectionRef: RefObject<HTMLDivElement> }) => {
  return (
    <Box
      ref={sectionRef}
      as="section"
      className="section faq increased-bottom-padding"
    >
      <Box className="container---main">
        <Box className="container---xl align-center">
          <Box className="faq-section">
            <Box className="faq-title">
              <h1 className="display-heading">FAQ</h1>
              <Box className="container---l">
                <Box className="large-text">
                  We’re here to answer any questions you may have. If you still
                  need support or have any further questions, don’t hesitate to
                  reach out to us via{' '}
                  <a href="mailto:care@picknfix.com?subject=FAQs">email.</a>
                </Box>
              </Box>
            </Box>
            <Accordion allowMultiple>
              {faqViews?.includes('one') && (
                <Box my={6} className="faq-wrapper">
                  <Box className="heading-three">Repairs</Box>
                  <AccordionItemComponent
                    title={`What Does ${getTextByPartner()} DO?`}
                    description={`${getTextByPartner()} is a repair transaction aggregation and management platform for consumer electronics. The service is the evolution of after-sales support by providing customers access to OEM-approved support services right from the comfort of home/office. It’s a 100% door-to-door service that offers you convenience and quality after-sales support services delivered by only OEM Authorized Service Centers.`}
                  />

                  <AccordionItemComponent
                    title="Can I speak with a customer care agent on the phone?"
                    description={`${getTextByPartner()} offers only remote solutions and services. All
                communication and inquiries are processed fully remotely
                through the App chat features and other chat platforms set
                in place by ${getTextByPartner()}. This feature can be found in
                right bottom of the mobile and web app.`}
                  />
                  <AccordionItemComponent
                    title="What is your repair duration?"
                    description="Repair duration is determined by the OEM service center your device is assigned to. To get an estimated repair duration kindly initiate your transaction on the app."
                  />
                  <AccordionItemComponent
                    title="Can I edit, or cancel a previously submitted order?"
                    description="Transactions once submitted cannot be rolled back or adjusted. To cancel or change a submitted request please email care@picknfix.com "
                  />
                </Box>
              )}

              {faqViews?.includes('two') && (
                <Box my={6} className="faq-wrapper">
                  <Box className="heading-three">About Warranties</Box>
                  <AccordionItemComponent
                    title="Does the warranty cover a Broken screen?"
                    description="Please be informed that a damaged or broken screen is not covered by a warranty, as the warranty only covers manufacturing defects. Kindly proceed to the app; choose the non-warranty option and follow the prompt, to enable you to continue with the transaction."
                  />

                  <AccordionItemComponent
                    title="What is considered a manufacturer&#x27;s defect/warranty?"
                    description="A technical flaw that arises during the warranty period and stops a device from being fully functional,hence the OEM Authorized Service Center are liable for repairs without charges. All other defects are nottermed manufacturer&#x27;s defect and will require charges from the customers for repair."
                  />
                  <AccordionItemComponent
                    title="How many days/months of warranty do I have after repairs?"
                    description={`${getTextByPartner()} assures you of the highest quality
                    service delivered ONLY through OEM Authorized Service
                    Centers. The post repairs warranty terms are
                    determined by the OEM Authorized Service Center and
                    required information will be available in the Terms
                    and Conditions made available to you upon initiation
                    of the transaction.`}
                  />
                  <AccordionItemComponent
                    title="Can I access warranty service without my proof of purchase?"
                    description="Unfortunately, warranty services cannot be accessed
                    without your providing valid proof of purchase which
                    must be issued by the device vendor. If you are unable
                    to establish eligibility for warranty, please process
                    your transaction as Out of Warranty to access
                    services."
                  />
                  <AccordionItemComponent
                    title="Do I Get charged when my device is still in warranty?"
                    description="Please be informed that the repair of the device under
                    warranty is absolutely free of charge. Our service
                    policy is only to charge for logistics which covers the
                    cost of pickup and delivery of your device from your
                    nominated address to the OEM SC and back to you."
                  />
                </Box>
              )}

              {faqViews?.includes('three') && (
                <Box my={6} className="faq-wrapper">
                  <AccordionItemComponent
                    title=" I cannot find my address on the map for my pick-up/delivery location?"
                    description=" You can able to enter the address manually into the app if it is not in the drop-down list."
                  />
                  <AccordionItemComponent
                    title="What are your payment terms?"
                    description={`${getTextByPartner()} is a Prepaid cashless service and ALL payments are strictly made through the app before transactions are confirmed and booked.`}
                  />
                  <AccordionItemComponent
                    title={`Are device model and serial number COMPULSORY to
                    book a transaction on the ${getTextByPartner()} app?`}
                    description="In order to enable us to track and monitor the repair
                    transaction of your device, it is compulsory that either
                    the IMEI or Serial number is submitted on the app for
                    out-of-warranty transactions. For In warranty
                    transaction,you will be required to additionally submit
                    your proof of purchase."
                  />
                </Box>
              )}
            </Accordion>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export async function getStaticProps() {
  return { props: {} };
}

export default Faq;
