import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';

const AccordionIcon = styled.img`
  transition: 0.3s;
  &.rotate {
    // display: none;
    transform: rotate(45deg);
  }
`;

const AccordionItemComponent = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <AccordionItem mb={4} className="accordion-item" px={2}>
    {({ isExpanded }) => (
      <>
        <Box className="accordion-title">
          <AccordionButton
            _hover={{ backgroundColor: 'transparent' }}
            className="text-bold"
          >
            <Box as="span" flex="1" textAlign="left">
              <strong className="bold-text">{title}</strong>
            </Box>
            <Box className="accordion-circle">
              <AccordionIcon
                src="/images/icon-accordion-plus.svg"
                alt="Toggle Accordion"
                width="14"
                height="14"
                className={`accordion-circle-icon ${
                  isExpanded ? 'rotate' : ''
                }`}
              />
            </Box>
          </AccordionButton>
        </Box>
        <AccordionPanel pt={4} pb={8}>
          {description}
        </AccordionPanel>
      </>
    )}
  </AccordionItem>
);
export default AccordionItemComponent;
